import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const RecentScholarlyImpactPage = () => (
  <Layout>
    <Seo title="Contact" />
    <div id="content">
      <div id="feature_box"></div>
      <div class="post_box top" id="post-44">
        <div class="format_text">
          <h1>Contact</h1>
          <p>
            <StaticImage
              src="../images/shutterstock_19733602-Contact.jpeg"
              alt="Mailboxes"
            />
          </p>
          <p>
            For general inquiries, please e-mail:{" "}
            <a href="mailto:Lawrence.J.Trautman@gmail.com">
              Lawrence.J.Trautman@gmail.com
            </a>
          </p>
          <div class="wpcf7" id="wpcf7-f1-p44-o1"></div>
        </div>
      </div>
    </div>
  </Layout>
)

export default RecentScholarlyImpactPage
